<template>
  <div v-if="user">
    <div class="row">
      <div class="col float-right text-right my-3">
        <date-picker v-model="dateRange" range range-separator=" - " format="MMM DD, YY"></date-picker>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card text-center">
            <div class="row">
              <div class="col-sm-4">
                <div class="stat-head">Total Reviews</div>
                <div class="big-numbers text-darker-blue">{{stats.total}}</div>
                <div class="stat-foot">Started At {{ stats.started_at_total }}</div>
              </div>
              <div class="col-sm-4">
                <div class="stat-head">New Reviews</div>
                <div class="big-numbers text-success">{{newReviews}}</div>
                <div class="stat-foot">All Time <strong class="text-success">+{{ allTimePercent }}%</strong></div>
              </div>
              <div class="col-sm-4">
                <div class="stat-head">New Reviews</div>
                <div class="big-numbers text-success">{{ stats.this_period_reviews }} <i v-if="stats.this_period_reviews > 0" class="uil uil-arrow-growth"></i></div>
                <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
              </div>
            </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="card text-center">
          <div class="row">
            <div class="col-sm-4">
              <div class="stat-head">Rating</div>
              <div class="big-numbers text-darker-blue">{{stats.average.toFixed(1)}}</div>
              <div class="stat-foot">Started At {{ stats.started_at_average.toFixed(1) }}</div>
            </div>
            <div class="col-sm-4 align-self-center">
              <div class="d-flex align-items-center">
                <table class="w-100 progess-table mb-2">
                  <tr>
                    <td width="20">5</td>
                    <td>
                      <b-progress variant="warning" :max="stats.total" :value="stats.overall_rating['5']" height="10px" />
                    </td>
                  </tr>
                  <tr>
                    <td width="20">4</td>
                    <td>
                      <b-progress variant="warning" :max="stats.total" :value="stats.overall_rating['4']" height="10px" />
                    </td>
                  </tr>
                  <tr>
                    <td width="20">3</td>
                    <td>
                      <b-progress variant="warning" :max="stats.total" :value="stats.overall_rating['3']" height="10px" />
                    </td>
                  </tr>
                  <tr>
                    <td width="20">2</td>
                    <td>
                      <b-progress variant="warning" :max="stats.total" :value="stats.overall_rating['2']" height="10px" />
                    </td>
                  </tr>
                  <tr>
                    <td width="20">1</td>
                    <td>
                      <b-progress variant="warning" :max="stats.total" :value="stats.overall_rating['1']" height="10px" />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="stat-head">Rating Change</div>
              <div class="big-numbers" :class="{ 'text-success': ratingChange >= 0, 'text-danger': ratingChange < 0 }">{{ ratingChange }}
                <i v-if="ratingChange > 0" class="uil uil-arrow-growth"></i>
                <i v-else-if="ratingChange < 0" class="text-danger  uil uil-chart-down"></i>
              </div>
              <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="user.business.is_tap_engine" class="col-sm-6">
        <div class="card text-center">
          <div class="row">
            <div class="col-sm-4">
              <div class="stat-head">Total Card Taps</div>
              <div class="big-numbers text-darker-blue">{{ stats.total_taps }}</div>
              <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
            </div>
            <div class="col-sm-4">
              <div class="stat-head">New Reviews</div>
              <div class="big-numbers text-success">{{ stats.this_period_reviews }}</div>
              <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
            </div>
            <div class="col-sm-4">
              <div class="stat-head">Conversion %</div>
              <div class="big-numbers text-success">{{ tapsConversion }}%</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="(user.business.is_tap_engine || user.business.is_text_requester)" class="col-sm-6">
        <div class="card text-center">
          <div class="row">
            <div class="col-sm-4">
              <div class="stat-head">Total Feedback</div>
              <div class="big-numbers text-darker-blue">{{ stats.feedback_total }}</div>
              <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
            </div>
            <div class="col-sm-4">
              <div class="stat-head">Positive</div>
              <div class="big-numbers text-success">{{ stats.feedback_positive }}</div>
              <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
            </div>
            <div class="col-sm-4">
              <div class="stat-head">Negative</div>
              <div class="big-numbers text-danger">{{stats.feedback_negative}}</div>
              <div class="stat-foot">This <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span></div>
            </div>
          </div>
        </div>
      </div>
      <div :class="{ 'col-sm-6': (user.business.is_tap_engine && user.business.is_reply_engine) || (user.business.is_text_requester) || (user.business.is_text_requester && !user.business.is_tap_engine), 'col-12': (!user.business.is_tap_engine && user.business.is_reply_engine) }">
        <div class="card">
          <div class="stat-head text-center mt-3">Recent Reviews</div>
          <ReviewList :key="user.id" :default-per-page="3" :for-dashboard="true" />
        </div>
      </div>
      <div v-if="user.business.is_tap_engine" class="col-sm-6">
        <div class="card">
          <div class="stat-head text-center mt-3">Current <span v-if="hasPeriodChanged">Period</span><span v-else>Month</span> Leaderboard</div>
          <div class="p-4">
            <table class="table text-left rank-table font-size-20 font-weight-bold mb-0">
              <thead>
              <tr>
                <th>
                  Rank
                </th>
                <th>
                  Name
                </th>
                <th class="text-right">
                  Card Taps
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(obj,i) in stats.leaderboard" :key="i" :class="`count-${obj.count}`">
                <td>
                  <div v-if="obj.count > 0">
                    #{{i + 1}}
                  </div>
                </td>
                <td>
                  <!--
                                  <img :src="`https://unavatar.io/${obj.email}`" :alt="obj.name" width="30" height="30" class="rounded-circle" />
                  -->
                  {{obj.name}}
                </td>
                <td class="text-right">
                  {{obj.count}}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewList from '@components/Common/ReviewsList.vue';
import DatePicker from 'vue2-datepicker';
export default {
  components:{
    ReviewList,
    DatePicker
  },
  data(){
    return {
      dateRange:null,
      hasDateLoaded: false,
      hasPeriodChanged:false,
      stats:{
        leaderboard:[],
        average:0,
        overall_rating:{
          1:0,
          2:0,
          3:0,
          4:0,
          5:0
        },
        monthly_rating:{
          1:0,
          2:0,
          3:0,
          4:0,
          5:0
        },
        total:0,
        started_at_total:0,
        started_at_average:0,
        month_start:null,
        month_end:null,
        this_period_reviews:0,
        this_period_average:0,
        total_taps:0,
        non_period_rating:0,
        feedback_total:0,
        feedback_negative:0,
        feedback_positive:0
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    tapsConversion(){
      let rate = Math.round((this.stats.this_period_reviews / this.stats.total_taps) * 100);
      if(isNaN(rate) || !isFinite(rate)){
        rate = 0;
      }
      return (rate)
    },
    ratingChange(){
      return (this.stats.average - this.stats.non_period_rating).toFixed(1);
    },
    newReviews(){
      return this.stats.total - this.stats.started_at_total;
    },
    allTimePercent(){
      return this.stats.total > this.stats.started_at_total  ? Math.round((this.newReviews / this.stats.started_at_total) * 100) : 0;
    }
  },
  watch: {
    dateRange(newDateRange, oldDateRange) {
      if(this.hasDateLoaded && oldDateRange !== null && JSON.stringify(newDateRange) !== JSON.stringify(oldDateRange)){
          this.hasDateLoaded = false;
          this.hasPeriodChanged = true;
         this.getReplyEngineStats(newDateRange[0].getTime()/1000,newDateRange[1].getTime()/1000)
      }
    },
  },
  mounted() {
    this.getReplyEngineStats();
  },
  methods:{
    getReplyEngineStats(start_date = null, end_date = null){
      this.$store
          .dispatch('business/getReplyEngineStats',{id:this.user.business.unique_id, start:start_date, end:end_date})
          .then((res) => {
            this.stats = res;
            this.dateRange = [new Date(this.stats.month_start),new Date(this.stats.month_end)];
            this.hasDateLoaded = true;
          })
          .catch((error) => {
            if(error.response){
              this.status = error.response.status;
            }
          })
    }
  }
}
</script>
<style lang="scss">
@import '~vue2-datepicker/scss/index.scss';
.progress-table{

}
.big-numbers{
  font-size: 4.5em;
  font-weight: bold;
  line-height: 1.25em;
  small{
    color: #737373;
    font-size: 14px;
  }
  i.uil{
    font-size: .75em;
    margin-left: -15px;
  }
}
.stat-head{
  margin-top: 10px;
  font-weight: bold;
  color: #17244d;
  font-size: 1.4em;
}
.stat-foot{
  color: #17244d;
  font-size: 1.3em;
  margin-bottom: 10px;
}
.text-darker-blue{
  color: #17244d;
}
.card-holder{
  min-height: 220px;
}
.card .card.rwg-app-panel{
  box-shadow: none;
  margin-bottom: 0;
}
@media (min-width: 768px) {
.row.equal {
  display: flex;
  flex-wrap: wrap;
}
}
</style>
<style scoped lang="scss">
.rank-table{
  tbody{
    tr{
      background: #e1e3e6;
      color: #737373;
      /*display: none;*/
    }
    tr:not(.count-0):nth-child(1){
      background: #0271be;
      color: #fff;
    }
    tr:not(.count-0):nth-child(2){
      background: #bc1823;
      color: #fff;
    }
    tr:not(.count-0):nth-child(3){
      background: #fff;
    }
    tr.active{
      display: table-row;
    }
  }
}
</style>
